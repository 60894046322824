<template>
  <div v-if="validToken">
    <h1 class="display-4">{{ $t('ALUMNI_CCID_TITLE') }}</h1>
    <p>{{ $t('ALUMNI_CCID_DESCRIPTION') }}</p>
    <div v-if="responseMessage" v-html="responseMessage" class="alert" v-bind:class="{'alert-danger':hasError,'alert-success':!hasError}" role="alert"></div>
    <form @submit.prevent="submit()" class="d-flex flex-column">
      <ValidatedTextField ref="firstName" name="firstName" :title="$t('LABEL_FIRST_NAME')" v-model="firstName" hideCheckmark :label-col=12 />
      <ValidatedTextField ref="lastName" name="lastName" :title="$t('LABEL_LAST_NAME')" v-model="lastName" hideCheckmark :label-col=12 />
      <ValidatedTextField ref="currentPhone" name="currentPhone" :title="$t('LABEL_CURRENT_PHONE')" v-model="currentPhone" :validations="['sms']" hideCheckmark :label-col=12 />
      <ValidatedTextField ref="currentEmail" name="currentEmail" :title="$t('LABEL_CURRENT_EMAIL')" v-model="currentEmail" :validations="['email']" hideCheckmark :label-col=12 />
      <ValidatedTextField ref="previousLegalNames" name="previousLegalNames" :title="$t('LABEL_PREVIOUS_LEGAL_NAMES')" v-model="previousLegalNames" hideCheckmark optional :label-col=12 />
      <ValidatedTextField ref="previousPhones" name="previousPhones" :title="$t('LABEL_PREVIOUS_PHONES')" v-model="previousPhones" hideCheckmark optional :label-col=12 />
      <ValidatedTextField ref="previousEmails" name="previousEmails" :title="$t('LABEL_PREVIOUS_EMAILS')" v-model="previousEmails" hideCheckmark optional :label-col=12 />
      <ValidatedTextField ref="previousAddresses" name="previousAddresses" :title="$t('LABEL_PREVIOUS_ADDRESSES')" v-model="previousAddresses" hideCheckmark optional :label-col=12 />
      <ValidatedTextField ref="degrees" name="degrees" :title="$t('LABEL_DEGREES')" v-model="degrees" hideCheckmark optional :label-col=12 />
      <button class="btn btn-primary align-self-end" ref="submit" name="submit" type="submit">{{ $t('BUTTON_SUBMIT') }}</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import ValidatedTextField from './ValidatedTextField.vue';
import {responseMessage} from '../mixins/responseMessage';

export default {
  mixins: [responseMessage],
  inject: ['queryParams'],
  data() {
    return {
      validToken: false,
      firstName: '',
      lastName: '',
      currentPhone: '',
      currentEmail: '',
      previousLegalNames: '',
      previousPhones: '',
      previousEmails: '',
      previousAddresses: '',
      degrees: '',
    }
  },
  mounted: async function() {
    await this.validateToken();
  },
  components: {
    ValidatedTextField,
  },
  methods: {
    async validateToken() {
      this.validToken = await axios.get('/alumniccid/validatetoken', this.queryParams)
        .then(() => true)
        .catch(() => false);
    },
    async validateFields() {
      return await this.$refs.firstName.validate()
        && await this.$refs.lastName.validate()
        && await this.$refs.currentPhone.validate()
        && await this.$refs.currentEmail.validate()
        && await this.$refs.previousLegalNames.validate()
        && await this.$refs.previousPhones.validate()
        && await this.$refs.previousEmails.validate()
        && await this.$refs.previousAddresses.validate()
        && await this.$refs.degrees.validate()
    },
    async submit() {
      if (!this.validateFields()) return;

      return await axios.post('/alumniccid/request', {
        token: this.token,
        firstName: this.firstName,
        lastName: this.lastName,
        currentPhone: this.currentPhone,
        currentEmail: this.currentEmail,
        previousLegalNames: this.previousLegalNames,
        previousPhones: this.previousPhones,
        previousEmails: this.previousEmails,
        previousAddresses: this.previousAddresses,
        degrees: this.degrees,
      })
      .then(() => {
        this.showMessage(this.$t('FORM_SUBMITTED'));
      })
      .catch((error) => {
        this.showError(error, this.$t('FORM_FAILED'));
      })
    },
  },
}
</script>
