<template>
  <div class="row">
    <label v-if="title" :class="'col-' + labelCol" class="control-label strong" :for="name">{{title}}</label>
    <div :class="'col-' + (title ? 12 - labelCol : 12)">
      <div class="d-flex gap-2">
        <input
          ref="value"
          :value="value"
          @input="updateValue($event.target.value)"
          @blur="validate($event.target.value)"
          class="form-control flex-fill"
          :class="{
            'is-valid': valid && !(hideCheckmark||readonly),
            'is-invalid': valid === false
          }"

          :name="name"
          :type="inputType"
          :placeholder="placeholder"
          :readonly="readonly"
          size="30"
        />
        <i
          v-if="masked"
          @click="unmasked = !unmasked"
          class="unmask fa align-self-center"
          :class="{
            'fa-eye':unmasked,
            'fa-eye-slash':!unmasked
          }">
        </i>
      </div>
      <ul class="errors">
        <li>{{ error }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {responseMessage} from '../mixins/responseMessage';

export default {
  mixins: [responseMessage],
  props: {
    value: String,
    title: String,
    name: String,
    validations: Array,
    validateMatch: String,
    validationPromise: Function,
    placeholder: String,
    masked: { type: Boolean, default: false },
    hideCheckmark: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false},
    autofocus: { type: Boolean, default: false},
    minLength: { type: Number, default: 0 },
    labelCol: { type: Number, default: 2 },
    optional: { type: Boolean, default: false },
  },
  data() {
    return {
      error: '',
      valid: null,
      unmasked: false
    }
  },
  computed: {
    capitalizedName() {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1)
    },
    inputType() {
      if (this.masked && !this.unmasked) return 'password'
      else if (this.validations && this.validations.includes('sms')) return 'tel';
      else return 'text';
    }
  },
  async mounted() {
    if (this.autofocus) this.focus();
  },
  methods: {
    updateValue(value) {
      if (this.name.startsWith('ccid'))
        value = value.toLowerCase();

      this.$emit('input', value);
    },
    async focus() {
      await this.$nextTick();
      this.$refs.value.focus();
    },
    async validate() {
      this.error = '';
      let validationPromises = [];

      if (this.value==='' && !this.optional) {
        this.valid = false;
        validationPromises.push(Promise.reject(this.$t('VALIDATION_REQUIRED')));
      } else if (this.minLength > 0 && this.value.length < this.minLength) {
        this.valid = false;
        validationPromises.push(Promise.reject(this.$t('VALIDATION_MINIMUM_LENGTH',{0:this.capitalizedName, 1:this.minLength})));
      }
      if (typeof this.validateMatch !== 'undefined' && this.validateMatch !== this.value) {
        validationPromises.push(Promise.reject(this.$t('VALIDATION_PASSWORD_MATCH')));
      }
      if (typeof this.validations !== 'undefined' && this.validations!==null) {
        if (this.validations.includes('password')) {
          if (this.value.length<10) {
            validationPromises.push(Promise.reject(this.$t('VALIDATION_MINIMUM_LENGTH',{0:this.capitalizedName, 1:10})));
          } else if (this.value.toUpperCase() == this.value) {
            validationPromises.push(Promise.reject(this.$t('VALIDATION_PASSWORD_LOWERCASE')));
          } else if (this.value.toLowerCase() == this.value) {
            validationPromises.push(Promise.reject(this.$t('VALIDATION_PASSWORD_UPPERCASE')));
          } else {
            await axios.post('/password/validate', { password: btoa(this.value) })
              .catch((error) => validationPromises.push(this.promiseReject(error, 'VALIDATION_COULD_NOT_COMPLETE')));
          }
        }
        if (this.validations.includes('alias')) {
          if (this.value.length < 9 && !this.value.includes(".")) validationPromises.push(Promise.reject(this.$t('ALIAS_LENGTH_RESTRICTION')));

          const alphanumericOrPeriod = /^[a-zA-Z0-9.]*$/;
          const containsAlpha = /[a-zA-Z]+/;
          if (!alphanumericOrPeriod.test(this.value) || !containsAlpha.test(this.value)) {
            validationPromises.push(Promise.reject(this.$t('ALIAS_VALUE_RESTRICTION')));
          }
        }
        if (this.validations.includes('email')) {
          const email = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
          if (!email.test(this.value)) {
            validationPromises.push(Promise.reject(this.$t('VALIDATION_EMAIL_INVALID')));
          }
        }
        if (this.validations.includes('sms')) {
          const phoneNumber = /(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/
          if (!phoneNumber.test(this.value)) {
            validationPromises.push(Promise.reject(this.$t('VALIDATION_SMS_INVALID')));
          }
        }
      }
      if (typeof this.validationPromise !== 'undefined' && this.validationPromise !== null) {
        validationPromises.push(this.validationPromise(this.value));
      }

      await Promise.all(validationPromises)
        .then(() => { this.valid = true;})
        .catch((err) => {
          this.valid = false;
          this.error = err;
        });

      return this.valid;
    }
  },
  watch: {
    value(v) {
      if (v === '') this.valid = null;
    },
    errors(err) {
      if (err.size > 0) this.valid = false;
      else if (err.size === 0) this.valid = true;
    }
  }
}
</script>

<style scoped>
li {
  text-align:right;
  list-style: none;
  color: darkred;
}
label {
  font-weight: normal;
  line-height: 20px;
  font-size: 15px;
  text-align: left;
  margin-top: 4px;
}
.unmask {
  min-width: 20px;
  color: #33333357;
}
@media (max-width: 480px) {
  label {
    line-height: 20px;
    margin-top: 0px;
    font-size: 14px;
  }
}
@media (max-width: 280px) {
 label {
    line-height: 18px;
    font-size: 13px;
 }
 li {
  max-width: 120px;
 }
}
</style>
